<template>
  <div>
    <section class="section">
      <div class="title">
        <span>
          <span
            class="news"
            @click="tabShow = true"
            :class="{ butActive: tabShow }"
            >员工福利</span
          >
          <span class="vertical"></span>
          <span
            class="activity"
            :class="{ butActive: !tabShow }"
            @click="tabShow = false"
            >团建活动</span
          >
        </span>
      </div>
      <div class="section-center">
        <div class="tab">
          <div class="tab-item" v-show="tabShow">
            <div class="row">
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/img1.png"
                  alt=""
                />
              </div>
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/img2.png"
                  alt=""
                />
              </div>
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/img3.png"
                  alt=""
                />
              </div>
              <div class="more" @click="detailsEvent('employeewelfare')">
                <div>
                  <p>节日福利</p>
                  <p>每月聚餐，定期团建、节日福利</p>
                  <p>More</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="more moreleft" @click="detailsEvent('employeewelfare')">
                <div>
                  <p>下午茶</p>
                  <p>每日水果、零食</p>
                  <p>More</p>
                </div>
              </div>
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/img4.png"
                  alt=""
                />
              </div>
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/img5.png"
                  alt=""
                />
              </div>
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/img6.png"
                  alt=""
                />
              </div>
            </div>

            <div class="columntitle">
              <span> 精彩时刻 </span>
              <span> Welfare </span>
              <span>
                <img
                  src="../../assets/image/enterpriseNews/lineimg.png"
                  alt=""
                />
              </span>
            </div>
            <div style="margin-bottom: 50px" class="row">
              
              <!-- <div>
                <img
                  src="../../assets/image/enterpriseNews/staff/img7.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="../../assets/image/enterpriseNews/staff/img8.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="../../assets/image/enterpriseNews/staff/img9.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="../../assets/image/enterpriseNews/staff/img10.png"
                  alt=""
                />
              </div> -->

                  <div class="mn-wrap"  @mouseover="enter1" @mouseleave="leave1">
                  <swiper :options="swiperOption1" ref="mySwiper1">
                    <swiper-slide><img src="../../assets/image/enterpriseNews/food-img1.png" alt="" style="width:342px;height:248px"></swiper-slide>
                    <swiper-slide><img src="../../assets/image/enterpriseNews/food-img2.png" alt="" style="width:342px;height:248px"></swiper-slide>
                    <swiper-slide><img src="../../assets/image/enterpriseNews/food-img3.png" alt="" style="width:342px;height:248px"></swiper-slide>
                    <swiper-slide><img src="../../assets/image/enterpriseNews/food-img4.png" alt="" style="width:342px;height:248px"></swiper-slide>
                      </swiper>
              </div>
            </div>
          </div>

          <div class="tab-item" v-show="!tabShow">
            <div class="row">
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/Leaguebuilding1.png"
                  alt=""
                />
              </div>
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/Leaguebuilding2.png"
                  alt=""
                />
              </div>
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/Leaguebuilding3.png"
                  alt=""
                />
              </div>
              <div class="more" @click="detailsEvent('stafftraining')">
                <div>
                  <p>团建活动</p>
                  <p>定期敬请期待···</p>
                  <p>More</p>
                </div>
              </div>
            </div>
            <div class="row" >
              <div class="more moreleft" @click="detailsEvent('stafftraining')">
                <div>
                  <p>员工培训</p>
                  <p>敬请期待···</p>
                  <p>More</p>
                </div>
              </div>
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/Leaguebuilding4.png"
                  alt=""
                />
              </div>
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/Leaguebuilding5.png"
                  alt=""
                />
              </div>
              <div class="imgs">
                <img
                  src="../../assets/image/enterpriseNews/staff/Leaguebuilding6.png"
                  alt=""
                />
              </div>
            </div>

            <div class="columntitle">
              <span> 精彩时刻 </span>
              <span> Welfare </span>
              <span>
                <img
                  src="../../assets/image/enterpriseNews/lineimg.png"
                  alt=""
                />
              </span>
            </div>
            <div style="margin-bottom: 50px" class="row">
                  <div class="mn-wrap"  @mouseover="enter" @mouseleave="leave">
                  <swiper :options="swiperOption" ref="mySwiper">
                    <swiper-slide><img src="../../assets/image/enterpriseNews/food-img1.png" alt="" style="width:342px;height:248px"></swiper-slide>
                    <swiper-slide><img src="../../assets/image/enterpriseNews/food-img2.png" alt="" style="width:342px;height:248px"></swiper-slide>
                    <swiper-slide><img src="../../assets/image/enterpriseNews/food-img3.png" alt="" style="width:342px;height:248px"></swiper-slide>
                    <swiper-slide><img src="../../assets/image/enterpriseNews/food-img4.png" alt="" style="width:342px;height:248px"></swiper-slide>
                      </swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  data() {
    return {
      tabShow: true,
       swiperOption: {
           loop: true,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                autoplay: {
                    delay: 10,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                speed: 2000,
                slidesPerView: 4,
                allowTouchMove: false
        
         },
            swiperOption1: {
           loop: true,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                autoplay: {
                    delay: 10,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                speed: 2000,
                slidesPerView: 4,
                allowTouchMove: false
        
         },
    };
  },
   components: {
    swiper,
    swiperSlide
  },
  methods: {
    detailsEvent(r) {
      this.$router.push("/" + r);
    },
    enter() {
        this.$refs.mySwiper.swiper.autoplay.stop()
    },
    leave() {
        this.$refs.mySwiper.swiper.autoplay.start()
    },
     enter1() {
        this.$refs.mySwiper1.swiper.autoplay.stop()
    },
    leave1() {
        this.$refs.mySwiper1.swiper.autoplay.start()
    }
  },
};
</script>
<style lang="scss">
// .mn-wrap {
//     width: 1300px;
//     height: 248px;
//     // background: #FFFFFF;
//     overflow: hidden;
//     margin: 50px;
//     position: relative;
// }
.swiper-container{
    width: 1466px;
    height: 100%;
}
 .swiper-wrapper{
    -webkit-transition-timing-function: linear;    /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    margin: 0 auto;
  }
// .swiper-container .swiper-slide{
//     // width: 100%;
//     // height: 100%;
//     // display: block;
//     // overflow: hidden;
// }
.swiper-container .swiper-slide{
    width: 350px !important;
    height: 248px !important;
    margin-top: 40px;
    margin-left: 26px;
}


// .mn-swiper .swiper-wrapper{
//     width: 100%;
//     margin: 0 auto;
// }

</style>
<style lang="scss" scoped>
.title {
  padding-top: 50px;
  span {
    span {
      display: inline-block;
      font-size: 30px;
      color: #999;
      cursor: pointer;
    }
    .vertical {
      width: 1px;
      height: 30px;
      background: #999;
      margin: 0 30px;
      vertical-align: sub;
    }
  }
  .butActive {
    color: #6c35e4;
  }
}
.section {
  background-image: url("../../assets/image/enterpriseNews/list/newsbg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  .section-center {
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .tab {
      padding-top: 40px;
      .tab-item {
        .row {
          display: flex;
          justify-content: space-between;
          .imgs{
            overflow: hidden;
             img {
                width: 100%;
          }
          }
          .more {
            width: 366px;
            height: 248px;
            cursor: pointer;
            div {
              padding: 54px 28px;
              p:nth-child(1) {
                height: 30px;
                font-size: 30px;
                margin-bottom: 28px;
              }
              p:nth-child(2) {
                font-size: 22px;
                margin-bottom: 54px;
              }
              p:nth-child(3) {
                font-size: 26px;
                
              }
            }
            background: #6c35e4;
            text-align: right;
            color: #f9fbfb;
          }
          .moreleft {
            text-align: left;
            background: #6f5990;
            div {
              padding: 54px 0 0 28px;
            }
          }
        }
        .columntitle {
          display: flex;
          justify-content: space-between;
          line-height: 30px;
          color: #6f36eb;
          text-align: left;
          margin: 85px 0 48px 0px;
          span {
            font-size: 30px;
            display: inline-block;
          }
          span:nth-child(2) {
            margin: 0 10px 0 14px;
            font-size: 28px;
            color: #ccc;
          }
          span:nth-child(3) {
            img {
              width: 100%;
            }
            max-width: 1160px;
          }
        }
      }
    }
  }
}

// hover事件效果
.section .section-center .tab .tab-item .row .imgs:hover img{
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
</style>